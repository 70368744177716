/**
 * Fangle Ratio
 *
 * Plugin that allows elements
 * to keep their ratio.
 *
 * This is a fork of this plugin:
 * https://github.com/loonkwil/jquery.keep-ratio
 *
 * This version adds several features:
 *
 * - Minimum viewport width
 * - Maximum height
 *
 */
(function(window) {
    'use strict';

    var $ = window.jQuery;
    var raf = window.requestAnimationFrame;

    /**
     * @type {{ratio: number, calculate: string}}
     */
    var defaultOptions = {
        ratio: 4/3,
        calculate: 'height',
        minwidth: false,
        maxheight: false
    };

    /**
     * @param {jQuery} $el
     * @param {{ratio: number, calculate: string}} options
     * @param {boolean=} forceRendering
     * @return {jQuery}
     */
    var resize = function($el, options, forceRendering) {

        if (options.minwidth && $(window).width() < options.minwidth)
        {
            var bg_img = $el.css('background-image');
            $el.attr('style', '');
            $el.css('background-image', bg_img);
            return;
        }

        var resizeFunction;

        if (options.calculate === 'height') {
            var width = $el.width();
            var new_height = Math.round(width/options.ratio);

            if (options.maxheight && new_height > options.maxheight) {
                resizeFunction = function() {
                    $el.height(options.maxheight);
                };
            } else {
                resizeFunction = function() {
                    $el.height(Math.round(width / options.ratio));
                };
            }
        } else {
            var height = $el.height();
            resizeFunction = function() {
                $el.width(Math.round(height * options.ratio));
            };
        }

        if (forceRendering) {
            resizeFunction();
        } else {
            raf(resizeFunction);
        }

        return $el;
    };

    /**
     * @param {jQuery} $els
     * @param {{ratio: number, calculate: string}} options
     * @param {boolean=} forceRendering
     * @return {jQuery}
     */
    var resizeAll = function($els, options, forceRendering) {
        return $els.each(function() {
            resize($(this), options, forceRendering);
        });
    };


    /**
     * @param {{ratio: number, calculate: string}} options
     * @return {jQuery}
     */
    $.fn.keepRatio = function(options) {
        options = $.extend({}, defaultOptions, options);

        var $boxes = $(this);

        $(window).on('resize', function() {
            resizeAll($boxes, options);
        });

        return resizeAll($boxes, options, true);
    };

    /**
     * Data API
     */
    $(document).ready(function() {

        $('[data-ratio]').each(function() {

            var ratio           = $(this).data('ratio');
            var minwidth        = $(this).data('ratio-minwidth');
            var maxheight       = $(this).data('ratio-maxheight');

            var ratio_elements = ratio.split('/');

            $(this).keepRatio({
                    ratio: ratio_elements[0]/ratio_elements[1],
                    calculate: 'height',
                    minwidth: minwidth,
                    maxheight: maxheight
            });

        });

    });

}(window));