/**
 * Fangle Reveal
 *
 * Reveal an element on page scroll.
 *
 * Unlike most other libraries, this 'fixes' the element once
 * it has been fully revealed, making it visiable when you scroll 
 * back up. This allows it to work with elements that are taller
 * than the viewport.
 *
 * Usage: 
 *     <div data-reveal="once"><div>fdasfasdf</div></div> *
 * Or:
 *     <div data-reveal="always"><div>fdasfasdf</div></div>
 */

(function(window) {
    'use strict';

    var $ = window.jQuery;

    /**
     * Data API
     */
    $(document).ready(function() {

        $('[data-reveal]').each(function() {

            //  Reveal mode - once or always
            var $$mode = $(this).attr('data-reveal');

            //  Pointer to the container
            var $$outer = $(this);

            //  Pointer to the container's inner area
            var $$inner = $(this).children(":first")

            //  On scroll, reveal if in the viewport
            $(window).bind('scroll', function( e ) {
                    
                if ($$outer.hasClass('revealed')) {
                    return;
                }

                var thisMaxHeight = $$inner.height();
                var thisOffset = $$outer.offset();
                var triggerPoint = thisOffset.top - $(window).height();
                var scrollTop = $(window).scrollTop();

                if ( scrollTop > triggerPoint ) { 

                    e.preventDefault();

                    var revealAmount = ( scrollTop - triggerPoint );
                    
                    if (revealAmount > thisMaxHeight) {

                        $$inner.css( 'position', 'absolute');
                        
                        if ($$mode == 'once') {
                            $$outer.addClass('revealed');
                        }
                    }

                    else if (revealAmount <= thisMaxHeight) {

                        $$inner.css( 'position', 'fixed');
                        $$outer.css( 'height', revealAmount + 'px' );
                    
                    }  else {

                        $$inner.css( 'position', 'absolute');

                    }
                }
            });

            //  On load and resize, just show the elements if they're
            //  in the viewport.
            $(window).bind('load resize', function( e ) {
                
                var thisMaxHeight = $$inner.height();

                if ($(window).width() <= 480) {
                    $$outer.css( 'height', thisMaxHeight + 'px' );
                    $$inner.css( 'position', 'absolute');
                    $$outer.addClass('revealed');
                    return;
                }

                var thisMaxHeight = $$inner.height();
                var thisOffset = $$outer.offset();
                var triggerPoint = thisOffset.top - $(window).height();
                var scrollTop = $(window).scrollTop();

                if ( scrollTop > triggerPoint ) { 

                    $$inner.css( 'position', 'absolute');
                    $$outer.css( 'height', thisMaxHeight + 'px' );
                }
            });
        });
    });

}(window));