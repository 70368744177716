/**
 * Fangle Parallax
 *
 * Adds parallax to a contianer and
 *  it's image.
 *
 * This is a fork of this plugin:
 * http://www.franckmaurin.com/blog/the-parallax-effect-with-jquery/
 *
 * This version adds several features:
 *
 * - Responsive support
 * - Dynamic ratio
 */


(function(window) {
    'use strict';

    var $ = window.jQuery;

    /**
     * Data API
     */
    $(document).ready(function() {

        $('[data-parallax]').each(function() {

            //  How much of the original image should be shown, 
            //  and how much sound be revealed on scroll?
            var amount = $(this).data('parallax');

            //  Pointer to the container
            var $$ = $(this);

            //  Pointer to the container's image
            var $$image = $('img', $(this));

            $(window).bind('scroll load resize', function() {
                    
                    var windowTop = $(window).scrollTop();
                    var boundingRect = $$[0].getBoundingClientRect()
                    var boundingTop = boundingRect.top;
                    var windowHeight = $(window).height();
                    var ratioFromTop = (boundingTop / windowHeight) * 1.2;
                    var maxOffset = $$.height() * amount;
                    var newOffset = maxOffset * ratioFromTop;

                    $$image.css({
                        'margin-top': newOffset + 'px'
                    });

            });
        });
    });

}(window));