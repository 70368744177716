/**
 * Fangle Alternative Position
 *
 * Allows an element to be moved to
 * another position at another breakpoint.
 */
(function($) {

    var defaultOptions = {
        breakpoint: 768,
        altElement: null
    };

    $.fn.fangleAltPosition = function(options) {

        options = $.extend({}, defaultOptions, options);

        var originalElement = $(this);

        // There must be an element id.
        if (!originalElement.attr('id')) {
            console.error('alt position requires an element id');
            return;
        }

        // Make sure the new element exist
        if ($(options.altElement).length == 0) {
            console.error('count not find '+options.altElement);
            return;
        }

        // Wrap the original element in something
        // that we can use to go back to the original element.
        wrap_id = originalElement.attr('id')+'-container-wrap';
        originalElement.wrap('<div id="'+wrap_id+'"></div>');
        options.originalElementWrap = $('#'+wrap_id);

        $(window).on('resize', function() {
            moveElement(originalElement, options);
        });

        return moveElement(originalElement, options);
    }

    var moveElement = function(originalElement, options) {

        if ($(window).width() < options.breakpoint) {

            // Is the menu in the correct place? (ex: the mobile position)
            var find = $(options.altElement).find('#'+originalElement.attr('id')).length;

            if (find == 0) {
                $(options.altElement).append(originalElement);
            }

        } else {

            var find = options.originalElementWrap.find('#'+originalElement.attr('id')).length;

            if (find == 0)
            {
                options.originalElementWrap.append(originalElement);
            }
        }
    };

    /**
     * Data API
     */
    $(document).ready(function() {

        $('[data-alt-position]').each(function() {

            var altElement  = $(this).data('alt-position');
            var breakpoint  = $(this).data('alt-position-breakpoint');

            $(this).fangleAltPosition({
                breakpoint: breakpoint,
                altElement: altElement
            });

        });

    });

}(jQuery));